import React from "react";
import MenuButtonA from "./MenuButtonA";
import "../App.scss";

function Menu() {
  return (
    <>
      <MenuButtonA></MenuButtonA>
    </>
  );
}

export default Menu;
